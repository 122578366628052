<template>
  <div id="accountHolder">
    <header-nav :content="`${$t('pages_router_003')} / ${ $t('pages_router_047')}`" />
    <div class="common-card-body">
      <div class="edit-btn">
        <a-button
          shape="round"
          type="primary"
          @click="$router.push('/receive/holder_edit')"
        >
          <template #icon><plus-outlined /></template>
          {{ $t('pages_holder_001') }}
        </a-button>
      </div>
      <div class="search-form ant-round-form">
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_holder_002') }}: </span>
          <a-input
            v-model:value="searchQuery.holderNameEn"
            allow-clear
            @pressEnter="loadAccountHolders(1)"
            @blur="loadAccountHolders(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_holder_003') }}: </span>
          <a-select
            v-model:value="searchQuery.holderIdentity"
            allow-clear
            @change="loadAccountHolders(1)"
          >
            <a-select-option
              v-for="opt in identityList"
              :key="opt.code"
              :value="opt.code"
            >{{ opt.name }}</a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_holder_004') }}: </span>
          <a-select
            v-model:value="searchQuery.status"
            allow-clear
            @change="loadAccountHolders(1)"
          >
            <a-select-option
              v-for="status in statusList"
              :key="status.code"
              :value="status.code"
            >{{ status.name }}</a-select-option>
          </a-select>
        </div>
      </div>
      <div class="table-part">
        <a-table
          :scroll="{ x: 1200 }"
          :loading="loading"
          :pagination="false"
          :custom-header-row="() => { return {class: 'my-table-tr'}}"
          :columns="columns"
          :data-source="holderList"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'edit'">
              <a-space style="width: 100%; justify-content: space-between">
                <a-button
                  shape="round"
                  size="small"
                  class="btn-primary-empty-0"
                  @click="showDetail(record.id)"
                >{{ $t('pages_receive_009') }}</a-button>
                <a-button
                  v-if="['20', '40', '60'].includes(record.status)"
                  shape="round"
                  size="small"
                  danger
                  @click="$router.push({ path: '/receive/holder_edit', query: { id: record.id, status: record.status } })"
                >{{ ['20', '60'].includes(record.status) ? $t('pages_receive_011') : $t('pages_holder_063') }}</a-button>
              </a-space>
            </template>
          </template>
        </a-table>
        <table-page
          :total="total"
          :page-opts="searchQuery"
          @update-page="updatePage"
        />
      </div>
    </div>
    <holder-detail
      v-model:visible="visible"
      :holder-info="holderInfo"
    />
  </div>
</template>

<script>
import HeaderNav from '@/components/header-nav'
import TablePage from '@/components/table-page'
import HolderDetail from './components/holder-detail'
import { reactive, toRefs, computed } from 'vue'
import { _receive, _oss } from '@/api'
import { getAttrStatusList, getTradeStatusName, getCountryName } from '@/util'
import i18n from '@/locale'
export default {
  name: 'AccountHolder',
  components: {
    'header-nav': HeaderNav,
    'table-page': TablePage,
    'holder-detail': HolderDetail
  },
  setup () {
    const state = reactive({
      loading: true,
      visible: false,
      total: 0,
      searchQuery: {
        pageNo: 1,
        pageSize: 10,
        holderNameEn: null,
        holderIdentity: null,
        status: null
      },
      holderInfo: {},
      holderList: [],
      countryList: [],
      columns: [
        { key: 'holderNameEn', dataIndex: 'holderNameEn', title: i18n.global.t('pages_holder_002'), width: 200, ellipsis: true },
        { key: 'holderIdentity', dataIndex: 'holderIdentityText', title: i18n.global.t('pages_holder_003'), width: 150 },
        { key: 'holderArea', dataIndex: 'holderAreaText', title: i18n.global.t('pages_holder_005'), width: 150, ellipsis: true },
        { key: 'holderCertificateType', dataIndex: 'holderCertificateTypeText', title: i18n.global.t('pages_holder_006'), width: 150 },
        { key: 'holderCertificateNumber', dataIndex: 'holderCertificateNumber', title: i18n.global.t('pages_holder_007'), width: 200 },
        { key: 'holderRegistratDate', dataIndex: 'holderRegistratDate', title: i18n.global.t('pages_holder_008'), width: 150 },
        { key: 'status', dataIndex: 'statusText', title: i18n.global.t('pages_holder_004'), width: 120 },
        { key: 'remark', dataIndex: 'remark', title: i18n.global.t('pages_holder_009'), width: 200, ellipsis: true },
        { key: 'edit', title: i18n.global.t('common_text_001'), align: 'center', fixed: 'right', width: localStorage.getItem('locale-language') === 'en' ? 300 : 180 }
      ]
    })

    state.identityList = computed(() => {
      return getAttrStatusList('personAndCompany')
    })

    state.statusList = computed(() => {
      return getAttrStatusList('holderStatus')
    })

    const updatePage = (page, pageSize) => {
      state.searchQuery.pageNo = page
      state.searchQuery.pageSize = pageSize
      loadAccountHolders()
    }

    const showDetail = async (id) => {
      const res = await _receive.queryHolderDetail({ id })
      if (res.data.succ) {
        let urlsP = []
        let urlsC = []
        const { data } = res.data
        if (data.holderProveDocument) {
          urlsP = (await _oss.getOssFileUrl(data.holderProveDocument.split(','))).data.data
        }
        if (data.holderCertificateType) {
          urlsC = (await _oss.getOssFileUrl(data.holderCertificate.split(','))).data.data
        }
        state.holderInfo = Object.assign({}, data, {
          holderAreaText: getCountryName(data.holderArea, state.countryList),
          holderIdentityText: getTradeStatusName(data.holderIdentity, data.holderType === '00' ? 'holderPerson' : 'holderCompany'),
          holderCertificateTypeText: getTradeStatusName(data.holderCertificateType, data.holderType === '00' ? 'certificateType' : 'companyCertificateType'),
          holderSexText: getTradeStatusName(data.holderSex, 'sex'),
          holderProveDocumentUrls: Object.values(urlsP),
          holderCertificateUrls: Object.values(urlsC),
          statusText: getTradeStatusName(data.status, 'holderStatus'),
          finalBeneficialReqs: data.finalBeneficialReqs ? data.finalBeneficialReqs.map(item => {
            return Object.assign({}, item, {
              beneficialAreaText: getCountryName(item.beneficialArea, state.countryList),
              beneficialCertificateType: getTradeStatusName(item.beneficialCertificateType, 'certificateType'),
              beneficialSex: getTradeStatusName(item.beneficialSex, 'sex')
            })
          }) : []
        })
        state.visible = true
      }
    }

    const loadAreaPub = async () => {
      const res = await _receive.getAreaPub()
      if (res.data.succ) state.countryList = res.data.data
    }

    const loadAccountHolders = async () => {
      state.loading = true
      const res = await _receive.queryHolderPage(state.searchQuery)
      if (res.data.succ) {
        state.holderList = res.data.data.list.map(item => {
          return Object.assign({}, item, {
            holderAreaText: getCountryName(item.holderArea, state.countryList),
            holderIdentityText: getTradeStatusName(item.holderIdentity, item.holderType === '00' ? 'holderPerson' : 'holderCompany'),
            holderCertificateTypeText: getTradeStatusName(item.holderCertificateType, item.holderType === '00' ? 'certificateType' : 'companyCertificateType'),
            statusText: getTradeStatusName(item.status, 'holderStatus')
          })
        })
        state.total = res.data.data.total
      }
      state.loading = false
    }

    const init = async () => {
      await loadAreaPub()
      loadAccountHolders()
    }

    init()

    return {
      showDetail,
      updatePage,
      loadAccountHolders,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
#accountHolder {
  .common-card-body {
    .edit-btn {
      display: flex;
      justify-content: flex-end;
      padding: 0 18px;
    }
    .part-label {
      width: 188px;
    }
    .table-part {
      margin-top: 12px;
    }
    .account-header {
      .main-title {
        font-size: 24px;
        font-weight: 600;
      }
      .sub-title {
        margin-top: 12px;
        font-weight: 500;
      }
    }
    .account-body {
      margin-top: 32px;
      padding-bottom: 48px;
      .main-img {
        width: 288px;
      }
      .apply-btn {
        margin-top: 48px;
        justify-content: center;
        .single-btn, .batch-btn {
          min-width: 135px;
        }
        .batch-btn {
          margin-left: 36px;
        }
      }
    }
  }
}
</style>
