<template>
  <a-modal
    width="60%"
    wrap-class-name="holder-detail"
    :visible="visible"
    :mask-closable="false"
    :closable="false"
  >
    <template #title>
      <div class="flex-align">
        <span>{{ $t('pages_holder_043') }}</span>
        <span style="margin-left: auto">{{ $t('pages_holder_004') }}: {{ holderInfo.statusText }}</span>
      </div>
    </template>
    <a-alert v-if="holderInfo.remark && holderInfo.status === '20'" type="error" banner style="margin-bottom: 24px">
      <template #message>
        <span>{{ $t('pages_holder_010') }}: </span>
        <span>{{ holderInfo.remark }}</span>
      </template>
    </a-alert>
    <div class="holder-info">
      <div class="header-title">{{ $t('pages_holder_044') }}</div>
      <div class="info-body">
        <div class="item">{{ $t('pages_holder_002') }}: <span>{{ holderInfo.holderNameEn }}</span></div>
        <div v-if="['CN', 'HK'].includes(holderInfo.holderArea)" class="item">{{ $t('pages_holder_015') }}: <span>{{ holderInfo.holderName }}</span></div>
        <div class="item">{{ $t('pages_holder_003') }}: <span>{{ holderInfo.holderIdentityText }}</span></div>
        <div class="item">{{ $t('pages_holder_005') }}: <span>{{ holderInfo.holderAreaText }}</span></div>
        <div class="item">{{ $t('pages_holder_006') }}: <span>{{ holderInfo.holderCertificateTypeText }}</span></div>
        <div class="item">{{ $t('pages_holder_007') }}: <span>{{ holderInfo.holderCertificateNumber }}</span></div>
        <div class="item">{{ $t('pages_holder_061') }}: <span>{{ holderInfo.postalCode }}</span></div>
        <div class="item">{{ holderInfo.holderType === '00' ? $t('pages_holder_045') : $t('pages_holder_046') }}: <span>{{ holderInfo.holderRealAddress }}</span></div>
        <div class="item">{{ $t('pages_holder_008') }}: <span>{{ holderInfo.holderRegistratDate }}</span></div>
        <div v-if="holderInfo.holderType === '00'" class="item">{{ $t('pages_holder_022') }}: <span>{{ holderInfo.holderSexText }}</span></div>
        <div class="item-path">
          <span>{{ $t('pages_holder_048') }}: </span>
          <div class="path-list">
            <div
              v-for="(url, index) in holderInfo.holderProveDocumentUrls"
              :key="index"
              style="margin-right: 18px"
            >
              <a-image
                :width="100"
                :src="url"
                :fallback="require('../../../../assets/icons/ic-graphic.png')"
                @click="openUrl(url)"
              />
            </div>
          </div>
        </div>
        <div class="item-path" style="margin-top: 24px">
          <span>{{
            holderInfo.holderType === '00' ? $t('pages_holder_049') : holderInfo.holderArea === 'CN'
              ? $t('pages_holder_054') : holderInfo.holderArea === 'HK'
                ? $t('pages_holder_055') : $t('pages_holder_053') }}: </span>
          <div class="path-list">
            <div
              v-for="(url, index) in holderInfo.holderCertificateUrls"
              :key="index"
              style="margin-right: 18px"
            >
              <a-image
                :width="100"
                :src="url"
                :fallback="require('../../../../assets/icons/ic-graphic.png')"
                @click="openUrl(url)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="holderInfo.holderType === '01'" class="final-area">
      <a-descriptions
        v-for="(item, index) in holderInfo.finalBeneficialReqs"
        :key="index"
        style="margin-bottom: 24px"
        :title="index === 0 ? $t('pages_holder_047') : ''"
      >
        <a-descriptions-item :label="$t('pages_holder_064')">{{ item.beneficialName }}</a-descriptions-item>
        <a-descriptions-item :label="$t('pages_holder_065')">{{ item.beneficialAreaText }}</a-descriptions-item>
        <a-descriptions-item :label="$t('pages_holder_066')">{{ item.beneficialCertificateType }}</a-descriptions-item>
        <a-descriptions-item :label="$t('pages_holder_067')">{{ item.beneficialCertificateNumber }}</a-descriptions-item>
        <a-descriptions-item :label="$t('pages_holder_068')">{{ item.beneficialDate }}</a-descriptions-item>
        <a-descriptions-item :label="$t('pages_holder_069')">{{ item.beneficialSex }}</a-descriptions-item>
      </a-descriptions>
    </div>
    <template #footer>
      <a-button type="primary" @click="$emit('update:visible', false)">{{ $t('common_text_024') }}</a-button>
    </template>
  </a-modal>
</template>
<script>
export default {
  name: 'HolderDetail',
  props: {
    visible: {
      type: Boolean,
      default: () => { return true }
    },
    holderInfo: {
      type: Object,
      default: () => { return {} }
    }
  },
  setup() {
    const openUrl = (url) => {
      const split = url.split('.')
      const splitAfter = split[split.length - 1]
      if (splitAfter && splitAfter.indexOf('jpg') === -1 && splitAfter.indexOf('jepg') === -1 && splitAfter.indexOf('png') === -1 &&
        splitAfter.indexOf('JPG') === -1 && splitAfter.indexOf('JEPG') === -1 && splitAfter.indexOf('PNG') === -1) {
        window.open(url)
      }
    }

    return {
      openUrl
    }
  }
}
</script>

<style lang="less">
.holder-detail {
  .holder-info {
    margin: -5px 0;
    .header-title {
      padding: 12px 24px;
      background: @table-th-color;
      font-weight: 500;
      font-size: 16px;
    }
    .info-body {
      display: flex;
      flex-wrap: wrap;
      padding: 18px 0;
      .item {
        flex: 0 0 33.33%;
        margin-bottom: 24px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 24px;
        img {
          width: 21px;
          height: 14px;
          margin: 0 5px 0 15px;
        }
        span {
          margin-left: 10px;
        }
      }
      .item-path {
        display: flex;
        padding: 0 24px;
        .path-list {
          display: flex;
          width: 500px;
          margin-left: 10px;
          white-space: nowrap;
          overflow-x: scroll;
        }
      }
    }
  }
  .final-area {
    padding: 0 30px;
  }
}
</style>
